import { Http, JsonResponse } from "@/types/http";
import { SponsorshipLogModel } from "@/types/sponsorship";
import { Loading } from "@twjs/vue-loading";
import { Notice } from "@twjs/vue-notice";
import { map } from "rxjs/operators";
import { defineComponent, inject, PropType } from "vue";
import { format } from "date-fns";

const ParticipantDetails = defineComponent({
  props: {
    isOpen: { type: Boolean },
    data: { type: Object as PropType<SponsorshipLogModel>, required: true },
    onHide: { type: Function },
  },
  render() {
    return (
      <section class="sponsorship-create p-8 overflow-y-auto modal-content-height">
        <h2 class="font-semibold text-2xl mb-2">Trip Details</h2>
        <section class="mh-600 w-full bg-white rounded-lg border border-solid border-gray-400 py-3 px-5 mt-5">
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">Trip ID</span>
            </label>
            <p class="font-semibold text-lg">{this.data.tripId > 0 ? this.data.tripId : ""}</p>
          </div>
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">User Name</span>
            </label>
            <p class="font-semibold text-lg">{`${this.data.firstName || ""} ${this.data.lastName || ""}`}</p>
          </div>
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">Start Date & Time</span>
            </label>
            <p class="font-semibold text-lg">
              {this.data.startTime && (
                <>
                  {format(new Date(this.data.startTime), "MM/DD/YYYY")}&nbsp;
                  <span class="text-xs">{format(new Date(this.data.startTime), "hh:mm A")}</span>
                </>
              )}
            </p>
          </div>
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">End Date & Time</span>
            </label>
            <p class="font-semibold text-lg">
              {this.data.endTime && (
                <>
                  {format(new Date(this.data.endTime), "MM/DD/YYYY")}&nbsp;
                  <span class="text-xs">{format(new Date(this.data.endTime), "hh:mm A")}</span>
                </>
              )}
            </p>
          </div>
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">From Address</span>
            </label>
            <p class="font-semibold text-lg">{this.data.startLocation}</p>
          </div>
          <div class="mb-4">
            <label class="flex flex-start items-center mb-2">
              <span class="font-semibold text-sm text-gray-p mb-0">Destination</span>
            </label>
            <p class="font-semibold text-lg">{this.data.endLocation}</p>
          </div>
        </section>
        {this.data.isCta === 1 && (
          <>
            <h2 class="font-semibold text-2xl mb-2 mt-8">Call To Action Details</h2>
            <section class="mh-600 w-full bg-white rounded-lg border border-solid border-gray-400 py-3 px-5 mt-5">
              <label class="flex flex-start items-center mb-2">
                <span class="font-semibold text-sm text-gray-p mb-0">CTA Type</span>
              </label>
              <pw-checkbox disabled isChecked={{ value: this.data.cta.type === 1 }} label="Landing Page" class="mt-6" />
              <pw-checkbox disabled isChecked={{ value: this.data.cta.type === 2 }} label="Mobile App" class="mt-6" />
              <pw-checkbox disabled isChecked={{ value: this.data.cta.type === 3 }} label="Embedded Forms" class="mt-6" />
              <pw-checkbox disabled isChecked={{ value: this.data.cta.type === 4 }} label="Lead Form" class="mt-6" />
              {this.data.cta.type === 4 && (
                <div class="mt-8">
                  <p class="text-sm font-semibold mb-2">Data Collected</p>
                  <p class="text-sm font-semibold text-gray-p mb-2">Email: {this.data.email}</p>
                  <p class="text-sm font-semibold text-gray-p mb-2">Phone: {this.data.phone}</p>
                </div>
              )}
            </section>
          </>
        )}
        <div>
          <w-pb onClick={this.onHide} class="mx-auto mt-6" text="Close" />
        </div>
      </section>
    );
  },
});

const defaultData: SponsorshipLogModel = {
  id: -1,
  phone: "",
  email: "",
  firstName: "",
  lastName: "",
  profile: "",
  dob: "",
  sex: -1,
  age: 0,
  startTime: "",
  endTime: "",
  startLocation: "",
  endLocation: "",
  tripId: -1,
  sponsorshipId: -1,
  sponsorshipName: "",
  isCta: 0,
  cta: {
    type: 0,
  },
};
export const SponsorshipLogModal = defineComponent({
  props: {
    isOpen: { type: Boolean },
    onHide: { type: Function },
    selectedId: { type: Number, required: true },
  },
  setup() {
    const $http = inject("$http") as Http;
    const $loading = inject("$loading") as Loading;
    const $notice = inject("$notice") as Notice;

    return { $http, $loading, $notice };
  },
  data() {
    return {
      data: { ...defaultData },
    };
  },
  watch: {
    isOpen(newValue) {
      if (!newValue) {
        this.data = { ...defaultData };
      }
    },
    selectedId(newValue) {
      if (newValue === -1) {
        this.data = { ...defaultData };
        return;
      }

      this.loadTrip(newValue);
    },
  },
  methods: {
    loadTrip(selectedId: number) {
      this.$loading.show();
      this.$http
        .get<JsonResponse<any>>(`/customer/trip/sponsorship/${selectedId}`)
        .pipe(
          map((json) => json.data),
          map(
            (data: any): SponsorshipLogModel => ({
              id: data.id,
              phone: data.phone,
              email: data.email,
              firstName: data.first_name,
              lastName: data.last_name,
              profile: data.profile,
              dob: data.dob,
              sex: data.sex,
              age: data.age,
              startTime: data.start_time,
              endTime: data.end_time,
              startLocation: data.start_location,
              endLocation: data.end_location,
              tripId: data.trip_id,
              sponsorshipId: data.sponsorship_id,
              sponsorshipName: data.sponsorship_name,
              isCta: data.is_cta,
              cta: {
                type: data?.cta?.action_type,
              },
            }),
          ),
        )
        .subscribe(
          (data) => {
            this.$loading.hide();
            this.data = data;
          },
          (err: Error) => {
            this.$loading.hide();
            this.$notice.e("Failed to load participant details: " + this.$http.getErrorData(err).message);
          },
        );
    },
  },
  render() {
    return (
      <modal
        customStyles={{ width: "768px", height: "fit-content", minHeight: "calc(100vh - 130px)" }}
        class="hidden-scroll"
        show={this.isOpen}
        onHide={this.onHide}
        title="Add New Sponsorship"
      >
        <w-tab text="Participants Details" />
        {this.isOpen && <ParticipantDetails data={this.data} isOpen={this.isOpen} onHide={this.onHide} />}
      </modal>
    );
  },
});
