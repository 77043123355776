interface DataTableProp {
  headers?: Array<{ title: string; class?: string; onClick?: Function }>;
  items: Array<any>;
  onRenderItem: (item: any, index: number) => void;
  className?: string;
  onRenderHeaders?: Function;
}

export const DataTable = (props: DataTableProp) => {
  const { headers, items, onRenderItem, className, onRenderHeaders } = props;
  return (
    <div class={`data-table w-full bg-white rounded-lg border border-solid border-color ${className}`}>
      <table>
        <thead>
          {onRenderHeaders ? (
            onRenderHeaders()
          ) : (
            <tr>
              {headers &&
                !!headers.length &&
                headers.map((o) => (
                  <th class={o.class} onClick={(e) => o.onClick?.(e)}>
                    {o.title}
                  </th>
                ))}
            </tr>
          )}
        </thead>
        <tbody>{items && items.length > 0 && items.map((o: any, idx: number) => onRenderItem(o, idx))}</tbody>
      </table>
      {items.length === 0 && <p class="p-2 text-center font-normal text-default-400">No Records</p>}
    </div>
  );
};
